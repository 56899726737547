.padel-container {
    background-color: rgb(var(--black-1));
    display: flex;
    flex-direction: column;
    width: calc(100vw - 150px - 64px);
    min-height: calc(100vh - 8px);
    padding-left: calc(150px + 56px);
    padding-top: 8px;
    padding-right: 8px;
}

.padel-page {
    width: 100vw;
    height: 100vh;
}

.padel-component {
    border-radius: 16px;
    background-color: white;
    margin: 8px;
    padding: 16px;
    height: fit-content;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.padel-navbar {
    width: 150px;
    margin: 16px 0px 16px 16px;
    height: calc(100vh - 64px);
    padding: 16px;
    position: fixed;
}

.padel-navbar-item {
    list-style: none;
    border-radius: 8px;
    padding: 8px;
    margin: 8px 0px;
    cursor: pointer;
}

.padel-navbar-item.active,
.padel-navbar-item:hover {
    background-color: rgb(var(--light-primary));
    color: rgb(var(--primary-hover));
    font-weight: bold;
    transition: 0.2s;
}

.padel-navbar-item .navbar-icon {
    margin-right: 8px;
    min-width: 18px;
}

.padel-selection {
    display: flex;
    background-color: white;
    align-items: center;
    padding: 16px;
    border-radius: 16px;
    margin: 8px;
    justify-content: space-between;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1)
}

.padel-club-select {
    min-width: 25%;
    margin-right: 8px;
}

.padel-date-select {
    height: 36px;
    border: 1px solid rgb(var(--black-2));
    border-radius: 4px;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;
}

.padel-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.padel-component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.padel-component-header h2 {
    margin: 0px;
}

.padel-component-header h1 {
    margin: 0px;
    margin-right: 8px;
}