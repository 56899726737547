.ruler {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 32px;
    background-color: rgba(var(--black-1), 0.6);
    width: 48px;
    border-radius: 24px;
    top: 160px;
    transition-duration: 0.2s;
}

.ruler:hover,
.ruler.active {
    cursor: pointer;
    background-color: rgba(var(--black-1), 0.8);
    transition-duration: 0.2s;
}

.ruler img {
    transition-duration: 0.2s;
}

.ruler:hover #ruler-icon,
.ruler.active #ruler-icon {
    transform: rotate(-45deg);
    transition-duration: 0.2s;
}

.ruler.active #bin-icon {
    opacity: 0.5;
    transition-duration: 0.2s;
}

.ruler-popup-container {
    position: fixed;
    bottom: 32px;
    width: 250px;
    background-color: rgb(var(--black-1));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition-duration: 0.2s;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
}

.ruler-popup-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: max-content;
    padding: 0px 8px;
    margin-bottom: 8px;
}

.ruler-popup-header h3 {
    font-size: 14px;
    color: rgb(var(--black-9));
    margin-left: 8px;
}

#ruler-popup-close {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 3px 6px;
    transition-duration: 0.5s;
}

#ruler-popup-close:hover {
    cursor: pointer;
    transform: rotate(90deg);
    transition-duration: 0.5s;
}

.ruler-popup-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: -8px 8px 8px 8px;
    font-size: 0.9rem;
}

.ruler-popup-distance {
    font-weight: bold;
    margin-left: 8px;
}

.ruler-marker {
    height: 12px;
    width: 12px;
    border-radius: 8px;
    border: 2px solid black;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    font-weight: bold;
}

.elevation-marker {
    height: 8px;
    width: 8px;
    border-radius: 16px;
    background-color: rgb(var(--primary));
    transition: 0.2s;
}

.elevation-marker:hover,
.elevation-marker.active {
    cursor: pointer;
    height: 16px;
    width: 16px;
    transition: 0.2s;
}

.ruler-altimetrie {
    text-decoration: underline;
    color: rgb(var(--primary));
}