#user-icon {
    height: 48px;
    width: 48px;
    background-color: rgb(157, 191, 222);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    font-weight: bold;
    font-size: 1.5rem;
    position: fixed;
    top: 32px;
    right: 32px;
    color: rgb(75, 104, 131);
    background-size: contain;
}

#user-icon:hover {
    cursor: pointer;
}