.layers-icon-container {
    width: 48px;
    height: 48px;
    position: absolute;
    right: 32px;
    background-color: white;
    border-radius: 50%;
    top: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(var(--black-1), 0.6);
    transition-duration: 0.2s;
    z-index: 1;
}

.layers-icon-container:hover,
.layers-icon-container.active {
    cursor: pointer;
    background-color: rgba(var(--black-1), 0.8);
    transition-duration: 0.2s;
}

.layers-icon-container img {
    height: 28px;
    width: 28px;
}

.layers-container {
    position: absolute;
    right: 96px;
    top: 96px;
    width: fit-content;
    height: fit-content;
    background-color: rgba(var(--black-1), 0.8);
    border-radius: 4px;
    padding: 8px 8px 2px 8px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(4px);
}

.layers-container h3 {
    margin: 0;
}

.layers-container ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: 6px;
}

.layers-container li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
    font-family: 'Source Sans Pro';
    margin: 6px 0px;
}

.layers-container li input {
    height: 20px;
    width: 20px;
    margin: 0;
    border-radius: 4px;
    margin-right: 8px;
    accent-color: rgb(var(--primary));
}

.layers-container li input:hover {
    cursor: pointer;
}

.layers-container li input.layer-settings {
    flex: 1;
    margin-left: 8px;
}

.layers-settings-icon {
    margin-left: 16px;
    color: #555;
}

.layers-settings-icon:hover {
    cursor: pointer;
    color: #333;
}

.layers-container .dl-layer {
    margin-right: 8px;
    height: 14px;
    width: 14px;
    background-color: rgb(var(--black-1));
    padding: 2px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.1s;
    border: 1px solid rgb(var(--black-5));
}

.layers-container .dl-layer svg {
    color: rgb(114, 114, 114);
}

.layers-container .dl-layer:hover {
    cursor: pointer;
    background-color: rgb(var(--black-2));
    transition-duration: 0.1s;
}

.layers-container .empty-layer {
    margin-right: 8px;
    height: 14px;
    width: 14px;
    background-color: rgb(255, 228, 204);
    padding: 2px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.1s;
    border: 1px solid rgb(var(--black-5));
}

.layers-container .empty-layer svg {
    color: rgb(248, 135, 37);
}

.layers-container .loading-layer {
    margin-right: 8px;
    height: 14px;
    width: 14px;
    background-color: rgb(var(--black-1));
    padding: 2px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.1s;
    border: 1px solid rgb(var(--black-5));
}

.layers-container .loading-layer svg {
    color: rgb(var(--black-7));
}

.layers-container svg {
    height: 12px;
    width: 12px;
}

.layer-control-label:hover {
    cursor: pointer;
    color: rgb(var(--primary));
}