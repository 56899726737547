.parcelle-container {
    margin: 0px 8px;
}

.parcelle-memo {
    flex: 1;
    border-radius: 4px;
    border: 1px solid rgb(var(--black-2));
    min-height: 100px;
    padding: 8px;
    font-size: 0.9rem;
    font-family: 'Arial'
}

.dvf-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 32px;
}

.dvf-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(var(--black-1));
    padding: 8px;
    border-radius: 4px;
}

.dvf-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
}

.dvf-header-right {
    display: flex;
    flex-direction: column;
}

.dvf-header svg {
    height: 32px;
    margin-right: 8px;
}

.dvf-content svg {
    height: 24px;
    margin-right: 16px;
}

.dvf-item {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(var(--black-1), 0.2);
    padding: 8px;
    margin-bottom: 16px;
}

.dvf-bold {
    font-weight: bold;
}

.dvf-info {
    font-size: 0.9rem;
}

.dvf-small {
    font-size: 0.7rem;
}

.dvf-right {
    display: flex;
    flex-direction: column;
}