.context-menu {
    position: absolute;
    width: fit-content;
    max-width: 350px;
    height: fit-content;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: rgba(var(--black-8), 0.8);
    color: white;
    backdrop-filter: blur(4px);
    overflow: hidden;
}

.context-menu_item {
    padding: 8px 16px;
    cursor: pointer;
    border-bottom: 1px solid rgb(var(--black-8));
}

.context-menu_item:last-child {
    border-bottom: none;
}

.context-menu_title {
    font-size: 1.2rem;
    font-family: 'Source Sans Pro Bold';
    padding: 8px 16px;
    border-bottom: 1px solid rgb(var(--black-8));
}

.context-menu_item:hover {
    background-color: rgba(var(--black-9), 0.9);
}