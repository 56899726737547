.search-results-container {
    width: 100%;
    height: fit-content;
    background-color: white;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid rgb(var(--black-2));
    border-top: none;
    width: 512px;
}

.search-results-container.closed {
    display: none;
}

.search-results-container.opened {
    display: block;
}

#search-bar.opened {
    border-bottom: none !important;
    border-radius: 4px 4px 0px 0px !important;
}

.search-tab-container {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 32px;
    padding: 0;
    margin: 0;
    border-left: 1px solid rgb(var(--black-2));
    border-right: 1px solid rgb(var(--black-2));
    border-bottom: 2px solid rgb(var(--black-1));
    width: 512px;
    background-color: white;
}

.search-tab-container li {
    margin: 0px 12px;
    color: rgb(var(--black-5));
    font-size: 0.8rem;
    font-weight: 500;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-tab-container li svg {
    margin-right: 4px;
}

.search-tab-container li.active {
    color: rgb(var(--black-9));
    font-weight: bold;
    border-bottom: 2px solid rgb(var(--black-9));
}

.search-tab-container li:hover {
    cursor: pointer;
    color: rgb(var(--black-7));
}

.search-item {
    height: 32px;
    font-size: 14px;
    width: 512px;
    transition-duration: 0.1s;
    display: flex;
    align-items: center;
}

.search-item span {
    margin: 0px 8px;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgb(var(--black-1));
    display: flex;
    align-items: center;
}

.search-item:hover {
    background-color: rgb(var(--black-1));
    cursor: pointer;
    transition-duration: 0.1s;
}

#search-bar input:focus {
    outline: none;
}

.plot-search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 512px;
    background-color: white;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgb(var(--black-2));
    border-bottom: none;
}

.plot-search-form {
    display: flex;
    height: 100%;
    margin-right: 4px;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
}

.plot-search-label {
    font-size: 0.7rem;
    margin: 0px 4px;
    color: rgb(var(--black-7));
}

.plot-search-input {
    height: 32px;
    border: none;
    background-color: rgba(var(--primary), 0.1);
    font-size: 1rem;
    text-align: center;
    margin: 0px 4px;
    padding: 0px 12px;
    border-radius: 64px;
    transition-duration: 0.2s;
    color: rgb(var(--primary));
    font-weight: bold;
}

.plot-search-input:focus,
.plot-search-input:hover {
    outline: none;
    background-color: rgba(var(--primary), 0.2);
    transition-duration: 0.2s;
}

.plot-search-form .top {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.plot-search-form .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    width: 100%;
}

#plot-search-city-select {
    flex: 1;
}