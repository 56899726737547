.collection-list-item-container {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 64px;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
    justify-content: space-between;
    transition-duration: 0.2s;
}

.collection-list-item-container:hover,
.plot-list-item-container:hover {
    cursor: pointer;
    background-color: rgb(250, 250, 250);
    transition-duration: 0.2s;
}

.collection-list-item-container .left,
.collection-list-item-container .right,
.plot-list-item-container .left,
.plot-list-item-container .right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.collection-list-item-container .collection-info {
    display: flex;
    flex-direction: column;
}

.plot-list-item-container {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 61px;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
    justify-content: space-between;
    transition-duration: 0.2s;
}