/* Importation de la police SourceSansPro-Black */
@font-face {
    font-family: 'Source Sans Pro Black';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-Black.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-BlackItalic */
@font-face {
    font-family: 'Source Sans Pro Black Italic';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-BlackItalic.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-Bold */
@font-face {
    font-family: 'Source Sans Pro Bold';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-Bold.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-BoldItalic */
@font-face {
    font-family: 'Source Sans Pro Bold Italic';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-ExtraLight */
@font-face {
    font-family: 'Source Sans Pro ExtraLight';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-ExtraLightItalic */
@font-face {
    font-family: 'Source Sans Pro ExtraLight Italic';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-Italic */
@font-face {
    font-family: 'Source Sans Pro Italic';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-Italic.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-Light */
@font-face {
    font-family: 'Source Sans Pro Light';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-Light.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-LightItalic */
@font-face {
    font-family: 'Source Sans Pro Light Italic';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-LightItalic.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-Regular */
@font-face {
    font-family: 'Source Sans Pro Regular';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-SemiBold */
@font-face {
    font-family: 'Source Sans Pro SemiBold';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('truetype');
}

/* Importation de la police SourceSansPro-SemiBoldItalic */
@font-face {
    font-family: 'Source Sans Pro SemiBold Italic';
    src: url('../assets/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
}