:root {
    --primary: 0, 136, 204;
    --primary-hover: 0, 121, 182;
    --secondary: 244, 244, 244;
    --light-primary: 223, 232, 245;
    --border-primary: 216, 226, 231;
    --hover-light-primary: 204, 220, 242;

    --black-10: 0, 0, 0;
    --black-9: 26, 26, 26;
    --black-8: 51, 51, 51;
    --black-7: 77, 77, 77;
    --black-6: 102, 102, 102;
    --black-5: 128, 128, 128;
    --black-4: 153, 153, 153;
    --black-3: 179, 179, 179;
    --black-2: 204, 204, 204;
    --black-1: 230, 230, 230;
    --black-0: 242, 242, 242;
}