.collection-tabs {
    display: flex;
    justify-content: space-evenly;
    background-color: rgb(var(--black-1));
    margin: 0px 8px;
    padding: 4px 0px;
    border-radius: 4px;
}

.collection-tabs button {
    background-color: transparent;
    border: none;
    font-size: 0.9rem;
    color: rgb(var(--black-7));
    transition-duration: 0.2s;
    flex: 1;
    margin: 0px 4px;
}

.collection-tabs button:focus,
.collection-tabs button.active {
    outline: none;
    background-color: white;
    color: rgb(var(--primary));
    border-radius: 4px;
}

#collection-tabs li.active {
    background-color: rgb(var(--secondary));
    border-top: 2px solid rgb(var(--primary));
    border-bottom: 1px solid rgb(var(--secondary));
    color: rgb(var(--black-9));
    font-weight: bold;
}

#collection-tabs li:not(.active):hover {
    cursor: pointer;
    background-color: rgb(var(--black-2));
}

.collections-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.collections-top-bar svg#collection-settings-icon {
    height: 24px;
    color: rgb(var(--black-8));
    margin: 0px 8px;
}

.collections-top-bar svg#collection-settings-icon:hover {
    cursor: pointer;
    color: rgb(var(--black-9));
}

.new-collection-container {
    display: flex;
    flex-direction: column;
}

.new-collection-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    margin-left: 8px;
}

#new-collection-input {
    height: 32px;
    background-color: transparent;
    border: none;
    margin-left: 8px;
    font-size: 1.5rem;
    font-weight: bold;
    flex: 1;
    margin-right: 8px;
    transition-duration: 0.2s;
    border-radius: 4px;
    color: rgb(var(--black-8));
}

#new-collection-input:hover {
    background-color: rgb(var(--black-1));
    transition-duration: 0.2s;
    color: rgb(var(--black-9));
}

#new-collection-input:focus {
    outline: none;
    color: rgb(var(--black-9));
    background-color: rgb(var(--black-1));
    transition-duration: 0.2s;
}

.new-collection-body h3 {
    margin: 8px 8px;
    font-size: 1.2rem;
}

.new-collection-criterias {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.new-collection-criterias .inputs {
    flex: 1
}

.new-collection-criterias #search-button {
    margin-left: 8px;
    padding: 12px;
    color: rgb(var(--primary));
    border: 1px solid rgb(var(--primary));
    border-radius: 8px;
    transition-duration: 0.2s;
}

.new-collection-criterias #search-button:hover {
    cursor: pointer;
    background-color: rgb(var(--light-primary));
    transition-duration: 0.2s;
}

.collection-desc {
    margin: 0px 8px;
    display: flex;
    height: fit-content;
    font-family: "Source Sans Pro";
}

.collection-desc p {
    margin: 0px 0px 8px 0px;
}

.collection-desc textarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    font-family: "Source Sans Pro";
    background-color: transparent;
    border: none;
    min-height: fit-content;
    height: 48px;
}

.collections-top-bar input {
    padding: 8px;
    border-radius: 4px;
    font-family: "Source Sans Pro Bold";
    font-size: 1.2rem;
    background-color: transparent;
    border: none;
    width: 100%;
}

.collection-desc textarea:focus,
.collection-desc textarea:hover,
.collections-top-bar input:focus,
.collections-top-bar input:hover {
    outline: none;
    background-color: rgb(var(--black-1));
}

.collections-top-bar .icon-button-container {
    margin: 0px 8px;
}

.new-collection-criterias .hor-align {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

/*  .new-collection-criterias .hor-align .input-group but not if full-width */
.new-collection-criterias .hor-align .input-group:not(.full-width) {

    flex: 1;
}

.new-collection-criterias .hor-align .input-group:not(.full-width) input {
    width: 128px;
    height: 36px;
    border: 1px solid rgb(var(--black-2));
    border-radius: 4px;
}

.new-collection-criterias .input-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0px;
    margin-top: 8px;
}

.new-collection-criterias .row {
    flex-direction: row;
    display: flex;
    width: 100%;

}

.new-collection-criterias .row .input-group.commune {
    flex: 1;
    margin-right: 8px;
}

.new-collection-criterias .row .surface-input {
    width: 128px;
    height: 36px;
    border: 1px solid rgb(var(--black-2));
    border-radius: 4px;
}

.new-collection-criterias .input-group.full-width {
    width: 100%;
}

.new-collection-criterias .selector {
    width: 100%;
}

.new-collection-criterias .selector :nth-child(4) {
    z-index: 2;
}

.new-collection-criterias .input-group .overlay {
    width: 512px;
    height: 57px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.allowed-users {
    margin: 0px 8px;
}

.allowed-user-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.user-infos {
    flex: 1
}

.allowed-user-email {
    font-size: 0.9rem;
    font-style: italic;
    color: rgb(var(--black-7));
}

.allowed-user-name {
    font-size: 1rem;
}

.allowed-user-select {
    flex: 1;
    margin-right: 8px;
}

.allowed-user-remove {
    border: 1px solid #ffd4d4;
    border-radius: 24px;
    padding: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4dcdc;
    color: #9b2f2f;
    transition-duration: 0.1s;
}

.allowed-user-remove:hover {
    cursor: pointer;
    background-color: #f8c6c6;
    transition-duration: 0.1s;
}

.hide-filters label {
    font-family: "Source Sans Pro Italic";
    font-size: 1rem;
    color: rgb(var(--black-6))
}

.hide-filters {
    display: flex;
    justify-content: center;
    align-items: center;
}

.peb-row input,
.peb-row select,
.max-bati input {
    height: 36px;
    border: 1px solid rgb(var(--black-2));
    border-radius: 4px;
}

.peb-row .input-group {
    margin-right: 32px;
}

.peb-row input {
    width: 128px;
}

.peb-row select {
    width: fit-content;
}

.peb-row #nearPlotsEnabled {
    height: 16px;
    width: 16px;
}

.peb-row .near-plots {
    flex-direction: row;
    align-items: center;
}

#collection-import {
    display: none;
}