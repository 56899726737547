.catchment-area-result-popup-container {
    cursor: default;
    transition-duration: 0.2s;
    width: calc(100% - 64px);
    height: calc(100% - 64px);
    background-color: rgb(var(--black-1));
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 32px;
    display: flex;
    flex-direction: column;
}

.catchment-area-result-popup-container .catchment-area-result-popup-header h3 {
    font-size: 1.3rem;
    margin-bottom: 0px;
}

.catchment-area-result-popup-container .catchment-area-result-popup-value.bold {
    font-weight: bold;
}

.catchment-area-result-popup-container #catchment-area-result-popup-close {
    position: absolute;
    cursor: pointer;
    top: 8px;
    right: 8px;
}

.catchment-area-result-popup-container .table-container {
    overflow: scroll;
    position: relative;
    border-radius: 4px;
    width: 100%;
    max-height: 100%;
}

/* .catchment-area-result-popup-container .table-container::-webkit-scrollbar {
    width: 8px;
}
  
.catchment-area-result-popup-container .table-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.catchment-area-result-popup-container .table-container::-webkit-scrollbar-thumb:hover {
    background-color: #555;
} */

.catchment-area-result-popup-container table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    padding: 16px;
    background-color: rgb(var(--light-primary));
    border-radius: 4px;
}

.catchment-area-result-popup-container table thead {
    position: sticky;
    top: 0;
    height: 48px;
    background-color: rgb(var(--primary));
    color: rgb(var(--black-1));
    z-index: 10;
}

.catchment-area-result-popup-container table thead th {
    font-size: 0.9rem;
}

.catchment-area-result-popup-container table thead th:first-child {
    text-align: left;
}

.catchment-area-result-popup-container table thead th:last-child {
    display: inline-flex;
    width: 100%;
    height: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.catchment-area-result-popup-container table thead th:last-child .proportional-toggle.active .toggle-outer {
    background-color: rgb(var(--primary-hover));
}

.catchment-area-result-popup-container th,td {
    height: 32px;
    padding: 4px 8px;
    text-align: center;
}

.catchment-area-result-popup-container tbody tr:nth-child(even) {
    background-color: rgb(var(--hover-light-primary));
    
}

.catchment-area-result-popup-container tbody th {
    text-align: left;
}

.catchment-area-result-popup-container tbody td.low-intersection-percentage {
    background-color: rgb(243, 72, 72);
    color: rgb(var(--black-1));
}

.catchment-area-result-popup-container tbody tr td:last-of-type {
    padding: 0;
}

.catchment-area-result-popup-container .proportional-toggle {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.catchment-area-result-popup-container .proportional-toggle .toggle-outer {
    height: 24px;
    width: 50px;
    background-color: rgb(var(--black-5));
    border-radius: 25px;
    transition-duration: 0.2s;
    cursor: pointer;
    position: relative;
}

.catchment-area-result-popup-container .proportional-toggle .toggle-inner {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 2px;
    top: 2px;
    background-color: rgb(var(--black-1));
    border-radius: 50%;
    transition-duration: 0.2s;
    cursor: pointer;
}

.catchment-area-result-popup-container .proportional-toggle.active .toggle-outer {
    background-color: rgb(var(--primary));
}

.catchment-area-result-popup-container .proportional-toggle.active .toggle-inner {
    transform: translateX(26px);
}

.catchment-area-result-popup-container .recalculate-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
}

.catchment-area-result-popup-container button.recalculate {
    width: fit-content;
    padding: 8px 16px;
    background-color: rgb(var(--black-5));
    color: white;
    border: none;
    border-radius: 4px;
    transition-duration: 0.2s;
    cursor: not-allowed;
    pointer-events: none;
}

.catchment-area-result-popup-container button.recalculate.available {
    background-color: rgb(var(--black-9));
    cursor: pointer;
    pointer-events: all;
}

.catchment-area-result-popup-container button.recalculate.available:hover {
    background-color: rgb(var(--black-8));
}