.icon-button-container {
    min-width: 28px;
    height: 28px;
    border: 1px solid rgb(var(--black-1));
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 0px 4px;
    transition-duration: 0.2s;
}

.icon-button-container:hover {
    background-color: white;
    border: 1px solid rgb(var(--primary));
    transition-duration: 0.2s;
}

.back-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    width: fit-content;
    padding: 0px 8px;
    background-color: rgb(var(--light-primary));
    border: 1px solid rgb(var(--light-primary));
    color: rgb(var(--primary));
    margin: 8px;
    border-radius: 4px;
    transition-duration: 0.2s;
    margin-bottom: 8px;
}

.back-button:hover {
    cursor: pointer;
    border: 1px solid rgb(var(--primary));
    transition-duration: 0.2s;
}

.back-button svg {
    margin-right: 8px;
}

h1 {
    font-family: "Source Sans Pro Bold";
    margin: 8px;
}

h2 {
    font-family: "Source Sans Pro Bold";
    font-size: 20px;
}

h3 {
    font-family: "Source Sans Pro Bold";
    font-size: 16px;
}

#app-dismiss {
    position: absolute;
    top: 12px;
    right: -14px;
    height: 32px;
    width: 32px;
    color: white;
    background-color: rgb(var(--black-5));
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    transition-duration: 0.2s;
    border-radius: 32px;
}

#app-dismiss::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -16px;
    height: 32px;
    width: 16px;
    background: rgb(var(--black-5));
}

#app-dismiss::after {
    content: "";
    position: absolute;
    top: -2px;
    right: -16px;
    height: 32px;
    width: 16px;
    background: rgb(var(--black-5));
}

#app-dismiss:hover {
    cursor: pointer;
    background-color: rgb(var(--black-6));
    color: rgb(var(--black-1));
    transition-duration: 0.2s;
}

.app-container.hidden {
    transform: translate(-100%);
    transition-duration: 0.3s;
    transition: ease-in-out 0.3s;
}

.app-container.visible {
    transition-duration: 0.3s;
    transition: ease-in-out 0.3s;
}

h1 {
    margin: 16px 8px;
    font-size: 1.5rem;
}

.login-container {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-container #login-button {
    height: 40px;
    padding: 8px 16px;
    background-color: rgb(var(--primary));
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    transition-duration: 0.2s;
    box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 20%);
}

.login-container #login-button:hover {
    cursor: pointer;
    background-color: rgb(var(--primary-hover));
    transition-duration: 0.2s;
}

h2 {
    margin-top: 16px;
    margin-bottom: 8px;
}

h3 {
    margin-top: 0px;
    margin-bottom: 8px;
}

span.primary-bold {
    color: rgb(var(--primary));
    font-weight: bold;
}

::-webkit-scrollbar {
    display: none;
}

.hover-info-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.2);
    transform: translateX(-50%);
    padding: 4px;
    border-radius: 4px;
    backdrop-filter: blur(8px);
    color: white;
    max-width: 25vw;
}

.hover-info-container span {
    padding: 4px;
    font-size: 1rem;
}

.hover-info-container .value {
    font-weight: bold;
}

.hover-info-container .name {
    font-weight: normal;
}

.custom-btn {
    background-color: #dedede;
    color: rgb(40, 40, 40);
    border: 1px solid #d2d2d2;
}

.custom-btn:hover {
    cursor: pointer;
    background-color: #d2d2d2;
    transition-duration: 0.1s;
}

.custom-btn.disabled {
    cursor: progress !important;
    filter: opacity(0.6);
}

.custom-btn.primary {
    background-color: rgb(var(--light-primary));
    color: rgb(var(--primary));
    border: 1px solid rgb(var(--border-primary));
}

.custom-btn.primary:hover {
    background-color: rgb(var(--hover-light-primary));
    transition-duration: 0.1s;
}

.custom-btn.danger {
    background-color: #f4dcdc;
    color: #9b2f2f;
    border: 1px solid #ffd4d4;
}

.custom-btn.danger:hover {
    background-color: #f8c6c6;
    transition-duration: 0.1s;
}

.custom-btn.success {
    background-color: #d1f2d9;
    color: #008117;
    border: 1px solid #b6f0c4;
}

.custom-btn.success:hover {
    background-color: #b6f0c4;
    transition-duration: 0.1s;
}

.filter-option {
    transition-duration: 0.1s;
    padding: 4px 12px;
    border-radius: 6px;
}

.filter-option:hover {
    transition-duration: 0.1s;
    background-color: rgb(var(--black-0));
}

#pluSwitchBtn {
    position: absolute;
    top: 96px;
    right: 32px;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    background-color: rgba(var(--black-1), 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.1s;
}

#pluSwitchBtn:hover,
#pluSwitchBtn.active {
    background-color: rgba(var(--black-1), 0.8);
    transition-duration: 0.1s;
}

#pluSwitchBtn img {
    height: 24px;
    width: 24px;
}

.overlay {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.new-account-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: fit-content;
    background-color: white;
    border-radius: 8px;
    padding: 16px
}

.new-account-popup h1 {
    margin: 8px 0px;
}

.new-account-popup .sub-title {
    font-family: "Source Sans Pro";
    font-size: 0.9rem;
    margin-bottom: 16px;
}

.new-account-popup label {
    font-size: "Source Sans Pro";
}

.new-account-popup input {
    height: 32px;
    width: 400px;
    border: 1px solid rgb(var(--border-primary));
    border-radius: 4px;
    padding: 4px;
    margin: 4px 0px;
    font-size: 1rem;
}

.clickable-icon {
    cursor: pointer;
}